/* Template */
.block {
  display: block; }

.hide {
  display: none; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.center-last {
  text-align-last: center; }

.left-last {
  text-align-last: left; }

.hidden {
  display: none; }

.btn:hover, .btn:focus {
  box-shadow: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.inline {
  display: inline; }

.inline-content {
  display: inline-block; }

.inline-table {
  display: inline-table; }

.img-responsive {
  margin: 0 auto;
  text-align: center; }

.auto-margin {
  margin: 0 auto; }

.block {
  display: block; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.relative {
  position: relative; }

.no-border {
  border: 0; }

.uppercase {
  text-transform: uppercase; }

.float-align-left {
  float: left; }

.float-align-right {
  float: right; }

.break-word {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word; }

.w-100 {
  width: 100% !important; }

/*
* FLEX CONTAINER
*/
.flex-container, .vertical-item--center, .vertical-item--top, .vertical-item--justify, .horizontal-item--start, .horizontal-item--around, .horizontal-item--between, .horizontal-item--center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.flex, .vertical-item--between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-column, .vertical-item--between {
  flex-flow: column; }

.flex-nowrap {
  flex-wrap: nowrap; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-between, .horizontal-item--between, .vertical-item--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-start, .vertical-item--justify {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-center, .horizontal-item--start, .horizontal-item--center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-items-center, .vertical-item--center, .horizontal-item--around, .horizontal-item--between, .horizontal-item--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-start, .vertical-item--top, .horizontal-item--start {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end; }

.align-self-end {
  align-self: flex-end; }

.align-self-start {
  align-self: flex-start; }

.align-self-center {
  align-self: center; }

.flex-initial {
  flex: initial; }

.flex-1 {
  flex: 1; }

.flex-inherit {
  flex: inherit; }

.flex-row {
  flex-flow: row; }

.loginWith {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333; }

.togglePass {
  right: 0;
  margin-right: 16px;
  align-self: center;
  z-index: 3;
  position: absolute;
  width: 22px;
  height: 10px;
  object-fit: contain; }

.togglePass:hover {
  cursor: pointer; }

.registerText {
  margin-top: 16px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #4b4b4b; }

.tree {
  background-image: url("../../../src/assets/images/ic-tree/ic-tree.png");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 162px;
  height: 289px;
  position: absolute;
  bottom: 0; }

a:hover {
  cursor: pointer; }

.PhoneInputCountryIcon {
  width: 35px;
  margin-right: 6px; }

.PhoneInputCountryIcon--border {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor); }

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }

.PhoneInputInput {
  flex: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.PhoneInput {
  display: flex;
  align-items: center; }

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  margin-right: var(--PhoneInputCountrySelect-marginRight); }

.titleMyDonation {
  font-family: 'Poppins';
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  color: #333333 !important; }

.amountDonation {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  color: #808080; }

.itemDonation:hover {
  cursor: pointer;
  border: 1px solid cornflowerblue !important; }

.statusDonation {
  right: 0;
  position: absolute; }

.titleListMyProject {
  font-family: 'Poppins';
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  color: #333333 !important; }

.lastUpdate {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  color: #808080; }

.titles {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #333333 !important; }

.organization {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #999999; }

.lineListProject {
  width: 99%;
  position: 'absolute';
  bottom: 0;
  border: 1px solid #000;
  opacity: 0.12;
  border: #f2fffa; }

.progres-bar {
  background-color: #e8e8e8; }

#label-collected {
  float: left;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333; }

#collected-amount {
  float: right;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #333333; }

#ellipsize {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical; }

.nav-link {
  color: rgba(255, 255, 255, 0.9) !important; }

.navbar {
  padding-left: 185px;
  padding-right: 185px; }

.dropdown-menu {
  padding: 0px !important;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropdown-item:active {
  color: #1aa59a !important;
  background-color: transparent !important; }

.dropdown-item:hover {
  color: #1aa59a !important; }

.img-overlay-login {
  position: absolute;
  height: 100vh;
  width: 51%;
  object-fit: cover; }

.logo-green-login {
  height: 84px;
  object-fit: contain; }

#body {
  font-family: Poppins;
  background-color: #f2fffa;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 80px;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vh; }
  #body .row {
    margin-left: 0;
    margin-right: 0; }
  #body.row {
    margin-left: 0;
    margin-right: 0; }

.label-new-project {
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  margin-bottom: 25px;
  align-items: center;
  margin-top: 64px; }
  .label-new-project .label-title {
    font-size: 36px;
    color: #333333;
    align-items: center;
    justify-content: center;
    font-weight: bold; }
  .label-new-project .button-container {
    align-items: center;
    justify-content: center; }
    .label-new-project .button-container button {
      font-weight: bold;
      padding: 8px 24px;
      background-color: #1aa59a;
      border-color: #1aa59a; }

.card-number {
  right: 0;
  font-size: 36px;
  font-weight: bold;
  min-width: 60px;
  text-align: end; }

.label-card {
  display: none; }

.img-list-project {
  width: 320px;
  height: 187px;
  object-fit: cover;
  border-radius: 4px; }

.desktop-show {
  display: block !important; }

.desktop-show-flex {
  display: flex !important; }

.mobile-show {
  display: none !important; }

.card-detail-html {
  word-break: break-word !important; }
  .card-detail-html img {
    max-width: 100%;
    height: auto !important; }

.react-contextmenu {
  z-index: 1; }

.font-16 {
  font-size: 16px !important; }

.font-14 {
  font-size: 14px !important; }

.font-12 {
  font-size: 12px !important; }

.font-10 {
  font-size: 10px !important; }

.box-shadow-remove {
  box-shadow: none !important; }

.break-word {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word; }

@media screen and (max-width: 767px) {
  .desktop-show {
    display: none !important; }
  .mobile-show {
    display: block !important; }
  .mobile-show-flex {
    display: flex !important; }
  #body {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 16px; }
  .w-100-mobile {
    width: 100% !important; }
  .block-mobile {
    display: block !important;
    margin-bottom: 8px !important;
    width: 100%; }
    .block-mobile > div {
      width: 100% !important;
      margin: 0 0 8px !important; }
  .font-mobile-24 {
    font-size: 24px !important; }
  .font-mobile-20 {
    font-size: 20px !important; }
  .font-mobile-18 {
    font-size: 18px !important; }
  .font-mobile-16 {
    font-size: 16px !important; }
  .font-mobile-14 {
    font-size: 14px !important; }
  .font-mobile-12 {
    font-size: 12px !important; }
  .font-mobile-10 {
    font-size: 10px !important; }
  .margin-0 {
    margin: 0 !important; }
  .margin-top-16 {
    margin-top: 16px !important; }
  .margin-bottom-16 {
    margin-bottom: 16px !important; }
  .padding-0 {
    padding: 0 !important; }
  .col-basis-1 {
    flex-basis: auto !important;
    margin: 0 !important; }
  /* Login Page */
  .row-login {
    margin-right: 0 !important;
    margin-left: -16px !important; }
  .img-overlay-login {
    width: 103%;
    height: 105%; }
  .p-login-overlay {
    text-align: right; }
  .logo-green-login {
    margin: 20px 20px 20px 35px;
    width: 130px;
    height: auto !important; }
  .registerText {
    font-size: 12px !important; }
  .label-masuk {
    font-size: 18px !important;
    margin-bottom: 16px !important; }
  .divider-line-login {
    width: 40%; }
    .divider-line-login .line {
      border: 1px solid #4b4b4b; }
  .atau-div {
    width: 20%;
    padding-left: 5px !important; }
    .atau-div div {
      font-size: 13px !important;
      line-height: 21px; }
  .login-form-container {
    margin: 50px 16px !important; }
  .language-container {
    margin: 20px 24px 0px !important; }
  #login-page a {
    font-size: 12px !important; }
  /* Login Page */
  /* Dashboard */
  .navbar {
    padding-left: 20px;
    padding-right: 20px; }
  #body {
    height: unset;
    width: unset;
    min-height: unset;
    min-width: unset; }
  .label-new-project {
    justify-content: space-between;
    margin: 24px 16px !important;
    padding: 0px;
    align-items: center; }
    .label-new-project .label-title {
      font-size: 18px;
      display: block;
      width: 100%; }
    .label-new-project .button-container {
      display: block;
      width: 100%;
      margin-top: 24px; }
      .label-new-project .button-container button {
        font-weight: bold;
        padding: 8px 24px;
        background-color: #1aa59a;
        border-color: #1aa59a;
        width: 100%; }
  .card-container {
    margin: 0px 16px 16px !important; }
    .card-container .col {
      display: inline-block;
      width: 50%; }
    .card-container .card {
      padding: 12px !important;
      margin-left: 0; }
      .card-container .card img {
        height: 24px !important; }
      .card-container .card .label-card-1 {
        margin: 0 !important;
        font-size: 16px !important;
        width: 100% !important;
        text-align: center;
        display: none; }
      .card-container .card .label-card {
        margin: 0 !important;
        font-size: 16px !important;
        width: 100% !important;
        text-align: center;
        display: block;
        min-width: 0px !important; }
      .card-container .card .card-number {
        right: unset;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        margin-top: 14px; }
  .div-all-projects {
    font-size: 16px !important;
    font-weight: bold;
    margin: 0 16px 16px !important; }
  .col-project-container {
    padding: 0 16px !important;
    margin-bottom: 16px !important; }
    .col-project-container .col-list-project {
      padding: 0 !important; }
      .col-project-container .col-list-project .img-list-project {
        max-width: 100%;
        max-height: 130px;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
        -webkit-filter: brightness(50%);
        filter: brightness(50%); }
      .col-project-container .col-list-project .div-img-title {
        position: relative;
        height: 130px;
        width: 100%; }
      .col-project-container .col-list-project .img-title-mobile {
        position: absolute;
        left: 8px;
        bottom: 8px;
        color: white !important;
        font-size: 14px !important;
        font-weight: bold;
        margin-bottom: 0 !important;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word; }
    .col-project-container .col-detail-project {
      margin: 0 !important;
      padding: 16px 8px !important; }
      .col-project-container .col-detail-project .progress {
        height: 6px !important; }
  .col-infinite-scroll {
    box-shadow: none !important; }
  /* Dashboard */
  /* Profile */
  .col-back-home {
    margin: 18px 0 !important; }
    .col-back-home img {
      height: 10px !important; }
    .col-back-home .back {
      font-size: 18px !important; }
  .div-profile {
    font-size: 24px !important;
    margin-bottom: 15px !important; }
  .div-info-akun {
    padding: 16px !important; }
    .div-info-akun .div-text-info {
      font-size: 16px !important; }
    .div-info-akun .btn-ubah-akun {
      padding: 3px 16px !important;
      font-size: 12px; }
    .div-info-akun .img-profile-container {
      width: 64px !important;
      height: 64px !important; }
      .div-info-akun .img-profile-container img {
        width: 64px !important;
        height: 64px !important; }
    .div-info-akun .div-name {
      font-size: 18px !important; }
    .div-info-akun .div-verified {
      margin-top: 0 !important; }
    .div-info-akun .user-level-mobile {
      margin: 0px;
      padding: 3px 8px;
      display: inline-block;
      border-radius: 16px;
      background-color: #50aa9f;
      margin-top: 5px; }
    .div-info-akun .div-name-email-form {
      margin-top: 16px !important; }
      .div-info-akun .div-name-email-form .col {
        flex-basis: auto;
        margin: 0 0 12px 0 !important; }
    .div-info-akun .col-ktp {
      margin-top: 0 !important; }
  .div-info-org {
    padding: 16px !important; }
    .div-info-org .div-text-info {
      font-size: 16px !important; }
    .div-info-org .btn-ubah-akun {
      padding: 3px 16px !important;
      font-size: 12px; }
    .div-info-org .div-name-email-form {
      margin-top: 16px !important; }
      .div-info-org .div-name-email-form .col, .div-info-org .div-name-email-form .col-lg-6 {
        flex-basis: auto;
        margin: 0 0 12px 0 !important; }
      .div-info-org .div-name-email-form .row {
        margin: 0 !important; }
  .div-info-pass {
    padding: 16px !important; }
    .div-info-pass .div-text-info {
      font-size: 16px !important; }
    .div-info-pass .btn-ubah-akun {
      padding: 3px 16px !important;
      font-size: 12px; }
  /* Profile */
  /* Edit Profile */
  .col-edit-profile-container .card {
    padding: 16px !important; }
    .col-edit-profile-container .card .img-profile-container {
      width: 64px !important;
      height: 64px !important; }
      .col-edit-profile-container .card .img-profile-container .camera-icon {
        height: 24px; }
      .col-edit-profile-container .card .img-profile-container .rounded-circle {
        width: 64px !important;
        height: 64px !important; }
    .col-edit-profile-container .card .div-name {
      font-size: 18px !important; }
    .col-edit-profile-container .card .div-verified {
      margin-top: 0 !important; }
    .col-edit-profile-container .card .user-level-mobile {
      margin: 0px;
      padding: 3px 8px;
      display: inline-block;
      border-radius: 16px;
      background-color: #50aa9f;
      margin-top: 5px; }
    .col-edit-profile-container .card .div-text-info {
      font-size: 16px !important; }
    .col-edit-profile-container .card .btn-primary {
      padding: 3px 12px !important;
      font-size: 12px; }
    .col-edit-profile-container .card .row-form .col, .col-edit-profile-container .card .row-form .col-lg-6 {
      flex-basis: auto;
      margin: 0 0 12px 0 !important; }
    .col-edit-profile-container .card .col-lg-6 {
      margin: 12px 0 12px 0 !important; }
    .col-edit-profile-container .card .row-ktp {
      margin-top: 0 !important; }
      .col-edit-profile-container .card .row-ktp #textBox {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .col-edit-profile-container .card .row-ktp button {
        padding: 7px 12px !important; }
  /* Edit Profile */
  .image-pohon-empty {
    width: 190px !important;
    height: auto !important; }
  .div-text-page-title {
    font-size: 20px !important; }
  /* Dashboard-detail */
  .dashboard-detail-container {
    padding-left: 16px !important;
    padding-right: 16px !important; }
    .dashboard-detail-container .col-detail div:first-child {
      font-size: 18px !important;
      margin-bottom: 20px !important; }
    .dashboard-detail-container .col-detail div:last-child .img-detail {
      width: 100% !important;
      max-height: 200px !important;
      margin: 0 !important;
      object-fit: cover; }
    .dashboard-detail-container .col-detail div:last-child .div-dana {
      width: 100% !important;
      margin: 0 !important;
      padding: 16px !important;
      word-break: break-word;
      background-color: white;
      cursor: default;
      border-color: transparent;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      text-align: left; }
      .dashboard-detail-container .col-detail div:last-child .div-dana .mobile-show {
        text-align: left !important; }
      .dashboard-detail-container .col-detail div:last-child .div-dana .total-dana {
        font-size: 16px !important; }
      .dashboard-detail-container .col-detail div:last-child .div-dana .target-dana {
        font-size: 14px !important; }
      .dashboard-detail-container .col-detail div:last-child .div-dana .progress {
        height: 6px !important; }
    .dashboard-detail-container .card-detail {
      padding: 16px !important;
      font-size: 14px !important; }
    .dashboard-detail-container .card-detail-html {
      padding: 16px !important; }
      .dashboard-detail-container .card-detail-html img {
        max-width: 100%;
        height: auto !important; }
    .dashboard-detail-container .list-pay {
      margin: 0 !important;
      padding: 16px !important; }
    .dashboard-detail-container .row-detail-dashboard-card {
      margin-top: 16px !important; }
  /* Dashboard-detail */
  /* Dashboard-donasi */
  .row-donasi-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .row-donasi-container .img-list-project {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 4px 4px 0 0;
      -webkit-filter: brightness(50%);
      filter: brightness(50%); }
    .row-donasi-container .div-img-title {
      position: relative; }
    .row-donasi-container .img-title-mobile {
      position: absolute;
      left: 8px;
      bottom: 8px;
      color: white !important;
      font-size: 14px !important;
      font-weight: bold;
      margin-bottom: 0 !important;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word; }
    .row-donasi-container .col-input-donasi {
      margin: 0 !important; }
      .row-donasi-container .col-input-donasi #number_format_2 {
        min-width: 200px; }
      .row-donasi-container .col-input-donasi .dropdown-item {
        padding: 10px 0; }
  /* Dashboard-donasi */
  /* Payment */
  .payment-qr-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .payment-qr-container .qr-container, .payment-qr-container .qr-card-container {
      margin: 0 !important; }
    .payment-qr-container .qr-container {
      margin-bottom: 16px !important; }
  .payment-kredit-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .payment-kredit-container .kredit-number-container, .payment-kredit-container .kredit-card-container {
      margin: 0 !important; }
    .payment-kredit-container .kredit-number-container {
      margin-bottom: 16px !important; }
  /* Payment */
  /* My Donation */
  .my-donation-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .my-donation-container .box-shadow-remove {
      box-shadow: none !important; }
    .my-donation-container .box-shadow-add {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px !important; }
    .my-donation-container .card-donation-list-container {
      margin-bottom: 16px !important; }
      .my-donation-container .card-donation-list-container .card-donation-list-inside {
        padding: 0 !important; }
        .my-donation-container .card-donation-list-container .card-donation-list-inside .div-img-title {
          position: relative;
          width: 100%; }
        .my-donation-container .card-donation-list-container .card-donation-list-inside .img-title-mobile {
          position: absolute;
          left: 8px;
          bottom: 8px;
          color: white !important;
          font-size: 14px !important;
          font-weight: bold;
          margin-bottom: 0 !important;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word; }
        .my-donation-container .card-donation-list-container .card-donation-list-inside .img-donation-list {
          max-width: 100%;
          max-height: 130px;
          height: auto !important;
          width: 100% !important;
          object-fit: cover;
          border-radius: 4px 4px 0 0;
          -webkit-filter: brightness(50%);
          filter: brightness(50%); }
        .my-donation-container .card-donation-list-container .card-donation-list-inside .col-amount {
          margin: 0 !important;
          padding: 16px 12px !important; }
  /* My Donation */
  /* Submission */
  .submission-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .submission-container .submit-button-row button {
      margin: 0 !important;
      width: 100%; }
    .submission-container .card-submission {
      padding: 8px 16px 16px !important;
      margin-top: 24px !important; }
      .submission-container .card-submission .card-label-title {
        font-size: 16px !important; }
      .submission-container .card-submission .garis {
        margin: 12px -16px !important; }
      .submission-container .card-submission input, .submission-container .card-submission textarea {
        max-width: 100%; }
      .submission-container .card-submission .col-basis-1 {
        flex-basis: auto !important;
        margin: 0 !important; }
      .submission-container .card-submission .margin-top-16 {
        margin-top: 16px !important; }
      .submission-container .card-submission .margin-bottom-16 {
        margin-bottom: 16px !important; }
      .submission-container .card-submission .tox-tinymce {
        height: 200px !important; }
      .submission-container .card-submission textarea {
        height: 156px; }
      .submission-container .card-submission .form-label {
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word; }
    .submission-container .image-edit-container > div:first-child {
      max-width: 100%;
      margin: 0 !important; }
      .submission-container .image-edit-container > div:first-child > div {
        max-width: 100%; }
      .submission-container .image-edit-container > div:first-child > img {
        max-width: 100%; }
    .submission-container .image-edit-container .label-gambar {
      margin: 12px 0 !important; }
      .submission-container .image-edit-container .label-gambar .titleMyProject {
        margin-right: 0 !important;
        word-break: break-word !important; }
    .submission-container .image-edit-container .badge-mobile {
      position: relative !important;
      right: unset;
      display: inline-block !important;
      margin-top: 12px !important; }
    .submission-container .uploader > div {
      font-size: 14px !important; }
    .submission-container .button-akses-container button {
      padding: 8px 12px !important;
      width: 50%; }
  /* Submission */
  /* Project List */
  .project-list-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .project-list-container .mobile-button {
      width: 100%;
      margin-bottom: 16px; }
    .project-list-container .box-shadow-remove {
      box-shadow: none !important; }
    .project-list-container .box-shadow-add {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px !important; }
    .project-list-container .card-donation-list-container {
      margin-bottom: 16px !important; }
      .project-list-container .card-donation-list-container .card-donation-list-inside {
        padding: 0 !important; }
        .project-list-container .card-donation-list-container .card-donation-list-inside .div-img-title {
          position: relative;
          width: 100%; }
        .project-list-container .card-donation-list-container .card-donation-list-inside .img-title-mobile {
          position: absolute;
          left: 8px;
          bottom: 8px;
          color: white !important;
          font-size: 14px !important;
          font-weight: bold;
          margin-bottom: 0 !important;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word; }
        .project-list-container .card-donation-list-container .card-donation-list-inside .img-donation-list {
          max-width: 100%;
          max-height: 130px;
          height: auto !important;
          width: 100% !important;
          object-fit: cover;
          border-radius: 4px 4px 0 0;
          -webkit-filter: brightness(50%);
          filter: brightness(50%); }
        .project-list-container .card-donation-list-container .card-donation-list-inside .col-amount {
          margin: 0 !important;
          padding: 16px 12px !important; }
          .project-list-container .card-donation-list-container .card-donation-list-inside .col-amount .titleMyProject {
            font-size: 14px !important;
            font-weight: bold !important; }
          .project-list-container .card-donation-list-container .card-donation-list-inside .col-amount .last-update {
            font-size: 10px !important; }
  /* Project List */
  /* User Management */
  .user-management-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .user-management-container input, .user-management-container button {
      max-width: 100%;
      width: 100% !important;
      margin-bottom: 8px !important; }
    .user-management-container .card {
      padding: 16px !important; }
      .user-management-container .card > .col .row {
        margin: 0 !important;
        padding: 0 !important; }
  /* User Management */
  /* PMT Dashboard */
  .pmt-dashboard-container {
    padding: 11px !important; }
    .pmt-dashboard-container .card-label-title {
      font-size: 18px !important; }
    .pmt-dashboard-container .graph-container {
      margin: 0 !important; }
      .pmt-dashboard-container .graph-container .card {
        width: 100% !important;
        padding: 16px !important;
        margin-bottom: 24px !important; }
    .pmt-dashboard-container .col-basis-1 {
      margin-bottom: 16px !important; }
    .pmt-dashboard-container .card-donation-list-container {
      margin-bottom: 16px !important; }
      .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside {
        padding: 0 !important; }
        .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside .div-img-title {
          position: relative;
          width: 100%; }
        .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside .img-title-mobile {
          position: absolute;
          left: 8px;
          bottom: 8px;
          color: white !important;
          font-size: 14px !important;
          font-weight: bold;
          margin-bottom: 0 !important;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word; }
        .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside .img-donation-list {
          max-width: 100%;
          max-height: 130px;
          height: auto !important;
          width: 100% !important;
          object-fit: cover;
          border-radius: 4px 4px 0 0;
          -webkit-filter: brightness(50%);
          filter: brightness(50%); }
        .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside .col-amount {
          margin: 0 !important;
          padding: 16px 12px !important;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word; }
          .pmt-dashboard-container .card-donation-list-container .card-donation-list-inside .col-amount > div {
            font-size: 14px !important; }
  /* PMT Dashboard */
  /* PMT Dashboard Detail */
  .dashboard-detail-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .dashboard-detail-container .submit-button-row button {
      margin: 0 !important;
      width: 100%; }
    .dashboard-detail-container .card-submission {
      padding: 8px 16px 16px !important;
      margin-top: 24px !important; }
      .dashboard-detail-container .card-submission .card-label-title {
        font-size: 16px !important;
        padding: 0 !important; }
      .dashboard-detail-container .card-submission .garis {
        margin: 12px -16px !important; }
      .dashboard-detail-container .card-submission input, .dashboard-detail-container .card-submission textarea {
        max-width: 100%; }
      .dashboard-detail-container .card-submission .col-basis-1 {
        flex-basis: auto !important;
        margin: 0 !important; }
      .dashboard-detail-container .card-submission .margin-top-16 {
        margin-top: 16px !important; }
      .dashboard-detail-container .card-submission .margin-bottom-16 {
        margin-bottom: 16px !important; }
      .dashboard-detail-container .card-submission .tox-tinymce {
        height: 200px !important; }
      .dashboard-detail-container .card-submission textarea {
        height: 156px; }
      .dashboard-detail-container .card-submission .form-label {
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word; }
      .dashboard-detail-container .card-submission .progress-container .progress-title {
        font-size: 14px !important;
        padding: 0 !important; }
      .dashboard-detail-container .card-submission .progress-container button {
        padding: 1px 5px !important;
        font-size: 12px !important;
        margin: 0 !important; }
      .dashboard-detail-container .card-submission .datepicker-container {
        margin: 0 !important;
        margin-top: 16px !important;
        background-color: transparent !important; }
        .dashboard-detail-container .card-submission .datepicker-container .react-date-picker {
          background-color: #e4f7f6;
          display: inline-block;
          width: 100%;
          margin-bottom: 5px !important; }
      .dashboard-detail-container .card-submission .card-donation-list-item {
        margin: 0 !important;
        padding: 8px 0 !important; }
      .dashboard-detail-container .card-submission .garis-donation-item {
        margin: 0 !important; }
    .dashboard-detail-container .image-edit-container > div:first-child {
      max-width: 100%;
      margin: 0 !important;
      width: 100% !important; }
      .dashboard-detail-container .image-edit-container > div:first-child > div {
        max-width: 100%; }
      .dashboard-detail-container .image-edit-container > div:first-child > img {
        max-width: 100%;
        max-height: 187px;
        width: 100% !important; }
    .dashboard-detail-container .image-edit-container .label-gambar {
      margin: 12px 0 !important; }
      .dashboard-detail-container .image-edit-container .label-gambar .titleMyProject {
        margin-right: 0 !important;
        word-break: break-word !important;
        font-size: 16px !important;
        font-weight: bold !important; }
      .dashboard-detail-container .image-edit-container .label-gambar .descMyProject {
        font-size: 14px !important;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word; }
    .dashboard-detail-container .image-edit-container .badge-mobile {
      position: relative !important;
      right: unset;
      display: inline-block !important;
      margin-top: 12px !important;
      font-size: 12px !important;
      font-weight: normal !important;
      padding: 8px 14px !important; }
    .dashboard-detail-container .uploader > div {
      font-size: 14px !important; }
    .dashboard-detail-container .button-akses-container button {
      padding: 8px 12px !important;
      width: 50%; }
  /* PMT Dashboard Detail */
  /* Add Progress */
  .add-progress-container {
    padding-left: 11px !important;
    padding-right: 11px !important; }
    .add-progress-container .submit-button-row button {
      margin: 0 !important;
      width: 100%; }
    .add-progress-container .card-submission {
      padding: 8px 16px 16px !important;
      margin-top: 24px !important; }
      .add-progress-container .card-submission .card-label-title {
        font-size: 16px !important;
        padding: 0 !important; }
      .add-progress-container .card-submission .garis {
        margin: 12px -16px !important; }
      .add-progress-container .card-submission input, .add-progress-container .card-submission textarea {
        max-width: 100%; }
      .add-progress-container .card-submission .col-basis-1 {
        flex-basis: auto !important;
        margin: 0 !important; }
      .add-progress-container .card-submission .margin-top-16 {
        margin-top: 16px !important; }
      .add-progress-container .card-submission .margin-bottom-16 {
        margin-bottom: 16px !important; }
      .add-progress-container .card-submission .tox-tinymce {
        height: 200px !important; }
      .add-progress-container .card-submission textarea {
        height: 156px; }
      .add-progress-container .card-submission .form-label {
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word; }
    .add-progress-container .image-edit-container > div:first-child {
      max-width: 100%;
      margin: 0 !important; }
      .add-progress-container .image-edit-container > div:first-child > div {
        max-width: 100%; }
      .add-progress-container .image-edit-container > div:first-child > img {
        max-width: 100%; }
    .add-progress-container .image-edit-container .label-gambar {
      margin: 12px 0 !important; }
      .add-progress-container .image-edit-container .label-gambar .titleMyProject {
        margin-right: 0 !important;
        word-break: break-word !important; }
    .add-progress-container .image-edit-container .badge-mobile {
      position: relative !important;
      right: unset;
      display: inline-block !important;
      margin-top: 12px !important; }
    .add-progress-container .uploader > div {
      font-size: 14px !important; }
    .add-progress-container .button-akses-container button {
      padding: 8px 12px !important;
      width: 50%; }
  /* Add Progress */ }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .img-overlay-login {
    width: 105%;
    height: 105%; }
  .card-container .col {
    width: 47%; } }

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}

@media screen and (max-width: 320px) and (orientation: portrait){}
*/
